import React from "react";
import PagePreloader from "./page";
import "../../assets/css/preloader.css";
import staveBrains from "../../assets/images/Brainslogo.png";

export default class Preloader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        pageLoading: false,
      });
    }, 2000);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="preloader"
          style={
            this.state.pageLoading ? { display: "block" } : { display: "none" }
          }
        >
          <div className="loading">
            <div className="line" />
            <div className="line" />
            <div className="line" />
            <div className="line" />
            <img className="PreloaderImage" src={staveBrains} alt="" />

            <div className="line" />

            <div className="line" />
            <div className="line" />
            <div className="line" />
          </div>
        </div>
        <PagePreloader />
      </React.Fragment>
    );
  }
}
