import React from "react";
import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Services from "./components/Services";
import RecentProjects from "./components/RecentProjects";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Team from "./components/Team";
import Preloader from "./components/preloader/Index";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Preloader />
        <Header />
        <Home />
        {/* <Services /> */}
        {/* <RecentProjects /> */}
        <AboutUs />
        <Team />
        <ContactUs />
      </BrowserRouter>
    </div>
  );
}

export default App;
